<template>
  <div class="d-page-wrapper d-home-page">
    <div class="d-home-page-title">{{ $t('homePage.title') }}</div>
    <div
      v-html="$t('homePage.text')"
      class="d-home-page-text"
    >
    </div>
    <v-btn
      :to="{ name: 'vehicles' }"
      class="mt-12"
      color="primary"
      depressed
    >
      {{ isAuthenticated ? $t('homePage.start'): $t('login') }}
    </v-btn>
  </div>
</template>


<script>
import pageMixin from '@/mixins/page.mixin'
import authMixin from '@/mixins/auth.mixin'


export default {
  mixins: [
    pageMixin,
    authMixin
  ],


  created () {
    this.setPageTitle('')
  }
}
</script>
